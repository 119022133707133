import { GROUPS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { z } from 'zod';
const maxGroupNameLength = 25;

export const useGroupFormSchema = (existingGroupNames: string[]) => {
  const { t } = useTranslation(GROUPS);

  const groupFormSchema = z.object({
    name: z
      .string()
      .min(1, { message: t('warning.please-enter-groupname') })
      .max(maxGroupNameLength, {
        message: t('warning.group-name-too-long', { length: maxGroupNameLength }),
      })
      .refine((name) => !existingGroupNames.includes(name), {
        message: t('warning.already-exists'),
      }),
    description: z.string().nullable(),
    userIds: z.array(z.string()),
  });

  return { groupFormSchema };
};
