import { CustomSearchableSelectOption } from '@/common/components/CustomSearchableSelect';
import { gql } from '@apollo/client';
import { useMemo } from 'react';
import { useGroupsQuery } from '../graphql/groups.generated';

type ReturnType = {
  selectGroups: CustomSearchableSelectOption[];
};

// GroupSelectみたいなcomponentを作ってそこで呼ぶべきだけど一旦ここで TODO
gql`
  query UseGroups {
    groups {
      id
      name
    }
  }
`;

export const useGroups = (): ReturnType => {
  const { data: groups } = useGroupsQuery();

  const selectGroups = useMemo(() => {
    return (
      groups?.groups?.map(({ id, name }) => {
        return {
          value: id,
          label: name,
        };
      }) ?? []
    );
  }, [groups]);

  return {
    selectGroups,
  };
};
