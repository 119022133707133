import FixedDataTable from '@/common/components/FixedDataTable';
import { GROUPS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { Button, TableContainer } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { FC } from 'react';
import type { GroupsTable_GroupFragment } from './GroupsTable.generated';

const columnHelper = createColumnHelper<GroupsTable_GroupFragment>();

export type GroupsTableProps = {
  groups: GroupsTable_GroupFragment[];
  handleGroupNameClick: (id: number) => void;
};

gql`
  fragment GroupsTable_Group on Group {
    id
    name
    description
    users {
      id
    }
  }
`;

const GroupsTable: FC<GroupsTableProps> = (props: GroupsTableProps) => {
  const { groups: groupsTableData, handleGroupNameClick } = props;
  const { t } = useTranslation(GROUPS);

  const columns = [
    columnHelper.display({
      id: 'name',
      maxSize: 16,
      cell: (info) => (
        <GroupName group={info.row.original} handleGroupNameClick={handleGroupNameClick} />
      ),
      header: t('form.groupname'),
      meta: {
        isClickDisabled: true,
      },
    }),
    columnHelper.accessor('description', {
      cell: (info) => info.getValue(),
      header: t('form.description'),
      enableSorting: true,
    }),
    columnHelper.accessor('users', {
      cell: (info) => info.getValue()?.length,
      header: t('form.member'),
      enableSorting: true,
    }),
  ];

  return (
    <TableContainer overflowY='auto' overflowX='unset' maxH='calc(100vh - 50px)' width='full'>
      <FixedDataTable columns={columns} data={groupsTableData} cellCursor='auto' />
    </TableContainer>
  );
};

type GroupNameProps = {
  group: GroupsTable_GroupFragment;
  handleGroupNameClick: (id: number) => void;
};

const GroupName = (props: GroupNameProps) => {
  const { group, handleGroupNameClick } = props;

  return (
    <Button
      colorScheme='primary'
      variant='link'
      // TODO delete 不要cast
      onClick={() => handleGroupNameClick(parseInt(group.id))}
    >
      {group.name}
    </Button>
  );
};

export default GroupsTable;
