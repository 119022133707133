import GroupUserSelectInput from '@/common/components/GroupUserSelectInput';
import { GROUPS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGroupsQuery } from '../graphql/groups.generated';
import type { GroupForm_GroupFragment } from './GroupForm.generated';
import { useGroupFormSchema } from './useGroupFormSchema';

type FormData = z.infer<ReturnType<typeof useGroupFormSchema>['groupFormSchema']>;
type FormActionProps =
  | {
      isEditing: true;
      updateGroup: (payload: FormData) => Promise<void>;
    }
  | {
      isEditing: false;
      createGroup: (payload: FormData) => Promise<void>;
    };

gql`
  fragment GroupForm_Group on Group {
    id
    name
    description
    users {
      id
    }
  }
`;

export type GroupFormProps = FormActionProps & {
  group?: GroupForm_GroupFragment;
  cancelGroupAction: () => void;
};

const GroupForm = (props: GroupFormProps) => {
  const { cancelGroupAction, isEditing, group } = props;
  const { t } = useTranslation(GROUPS);
  const { data: groupsData } = useGroupsQuery();
  const existingGroupNames = groupsData
    ? groupsData.groups.filter((g) => g.id !== group?.id).map((g) => g.name)
    : [];
  const { groupFormSchema } = useGroupFormSchema(existingGroupNames);
  const defaultValues = group
    ? {
        name: group.name,
        description: group.description,
        userIds: group.users?.map((user) => user.id),
      }
    : {
        name: '',
        description: '',
        userIds: [],
      };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useForm<FormData>({
    resolver: zodResolver(groupFormSchema),
    defaultValues,
  });

  const onSubmit = async (values: FormData) => {
    if (isEditing) {
      const { updateGroup } = props;
      await updateGroup(values);
    } else {
      const { createGroup } = props;
      await createGroup(values);
    }
  };

  return (
    <Box bg='neutral.50'>
      <VStack align='stretch'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box pb={{ base: 16, md: 0 }}>
            <FormControl isInvalid={!!errors.name} px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>
                {t('form.groupname')}
                <Text as='span' color='error.500'>
                  *
                </Text>
              </FormLabel>
              <Input {...register('name')} data-testid='group-form-name-input' autoComplete='off' />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>

            <FormControl px={4} py={2} bg='white'>
              <FormLabel color='neutral.800'>{t('form.description')}</FormLabel>
              <Input
                {...register('description')}
                data-testid='group-form-description-input'
                autoComplete='off'
              />
            </FormControl>

            <FormControl px={4} py={2} bg='white' data-testid='group-form-users-input'>
              <FormLabel>{t('form.member')}</FormLabel>
              <Controller
                control={control}
                name='userIds'
                render={({ field: { onChange, value } }) => {
                  return (
                    <GroupUserSelectInput
                      defaultValues={value ?? []}
                      onChange={(value) => onChange(value)}
                    />
                  );
                }}
              />
            </FormControl>
          </Box>
          <Flex
            justifyContent='flex-end'
            position={{ base: 'fixed', md: 'unset' }}
            width='full'
            bottom={0}
            left={0}
            p={{ base: 4, md: 2 }}
            backgroundColor={{ base: 'neutral.0', md: 'unset' }}
          >
            <Button
              variant='outline'
              colorScheme='primary'
              width={{ base: '50%', md: 'auto' }}
              onClick={() => cancelGroupAction()}
            >
              {t('actions.cancel')}
            </Button>

            <Button
              ml='2'
              colorScheme='primary'
              isLoading={isSubmitting}
              type='submit'
              width={{ base: '50%', md: '100px' }}
            >
              {t('actions.save')}
            </Button>
          </Flex>
        </form>
      </VStack>
    </Box>
  );
};

export default GroupForm;
