import { Button, TableContainer } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import FixedDataTable from '@/common/components/FixedDataTable';
import PopoverMessageWrapper from '@/common/components/PopoverMessageWrapper';
import { useApplicationContext } from '@/context/ApplicationContext';
import { POSITIONS } from '@/modules/users';
import { GROUPS } from '@/utils/i18n/constants';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import type React from 'react';
import type { GroupUsersTable_UserFragment } from './GroupUsersTable.generated';

export type GroupUsersTableProps = {
  users: GroupUsersTable_UserFragment[];
  handleRemoveUserFromGroupButtonClick: (userId: string) => void;
};
const columnHelper = createColumnHelper<GroupUsersTable_UserFragment>();

const getOfficeRolePosition = (officeRoles: GroupUsersTable_UserFragment['officeRoles']) => {
  if (officeRoles.length > 0) {
    return POSITIONS.filter((position) => position.value === officeRoles[0].role.position)[0].label;
  }

  return '';
};

gql`
  fragment GroupUsersTable_User on User {
    id
    name
    officeRoles {
      role {
        position
      }
    }
  }
`;

const GroupUsersTable: React.FC<GroupUsersTableProps> = (props: GroupUsersTableProps) => {
  const { users: groupUsersTableData, handleRemoveUserFromGroupButtonClick } = props;
  const { t, t_ns } = useTranslation(GROUPS);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: t('form.username'),
      enableSorting: true,
    }),
    columnHelper.accessor('officeRoles', {
      cell: (info) => {
        const value = info.getValue();
        const officeRole = getOfficeRolePosition(value);
        return value && officeRole ? t(`users.roles.${officeRole}`) : '';
      },
      header: t_ns('user-role'),
      enableSorting: true,
    }),
    columnHelper.display({
      id: 'remove',
      header: t('actions.remove'),
      maxSize: 16,
      cell: (info) => (
        <UserRowActions
          user={info.row.original}
          handleRemoveUserFromGroupButtonClick={handleRemoveUserFromGroupButtonClick}
        />
      ),
      meta: {
        isClickDisabled: true,
      },
    }),
  ];

  return (
    <TableContainer overflowY='auto' overflowX='unset' maxH='calc(100vh - 50px)' width='auto'>
      <FixedDataTable columns={columns} data={groupUsersTableData} cellCursor='auto' />
    </TableContainer>
  );
};

type UserRowActionsProps = {
  user: GroupUsersTable_UserFragment;
  handleRemoveUserFromGroupButtonClick: (userId: string) => void;
};

const UserRowActions = (props: UserRowActionsProps) => {
  const { user, handleRemoveUserFromGroupButtonClick } = props;
  const { t } = useTranslation(GROUPS);
  const { isCompanyOrOfficeAdmin } = useApplicationContext();

  return (
    <PopoverMessageWrapper
      message={t('warning.no-permission.edit')}
      isDisabled={!isCompanyOrOfficeAdmin}
    >
      <Button
        colorScheme='primary'
        variant='link'
        onClick={() => handleRemoveUserFromGroupButtonClick(user.id)}
        isDisabled={!isCompanyOrOfficeAdmin}
      >
        {t('actions.delete')}
      </Button>
    </PopoverMessageWrapper>
  );
};

export default GroupUsersTable;
