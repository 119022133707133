import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { useState } from 'react';
import CustomSearchableSelect, { CustomSearchableSelectOption } from './CustomSearchableSelect';
import { useGroupUserSelectInputSuspenseQuery } from './GroupUserSelectInput.generated';
import { SuspenseWithSpinner } from './SuspenseWithSpinner';

type GroupUserSelectInputProps = {
  defaultValues: string[];
  onChange: (usersValue: string[]) => void;
};

gql /* GraphQL */`
query GroupUserSelectInput {
    users {
      id
      name
    }
  }
`;

const GroupUserSelectInput = (props: GroupUserSelectInputProps) => {
  const { defaultValues, onChange } = props;
  const {
    data: { users },
  } = useGroupUserSelectInputSuspenseQuery();
  const { t } = useTranslation();

  const initialUsers = () => {
    if (users.length === 0) return [];
    return defaultValues.map((id) => {
      const user = users.find((user) => user.id === id);
      if (!user) throw new Error('user not found');
      return {
        value: user.id,
        label: user.name,
      };
    });
  };

  const [userValues, setUserValues] = useState<CustomSearchableSelectOption[]>(initialUsers);
  if (users === undefined) return null;

  const options = users.map((user) => ({ value: user.id, label: user.name }));

  const handleInput = (values: CustomSearchableSelectOption[]) => {
    if (values.length > 0) {
      onChange(values.map(({ value }) => value as string));
      setUserValues(values);
      return;
    }
    onChange([]);
    setUserValues([]);
  };

  return (
    <>
      <CustomSearchableSelect
        label={t('assignee')}
        placeholder={t('warning.please-select')}
        value={userValues}
        onChange={handleInput}
        options={options}
        isClearable
        isMulti
      />
    </>
  );
};

const GroupUserSelectInputWithSpinner = (props: GroupUserSelectInputProps) => (
  <SuspenseWithSpinner>
    <GroupUserSelectInput {...props} />
  </SuspenseWithSpinner>
);

export default GroupUserSelectInputWithSpinner;
